/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, ListSubheader } from '@material-ui/core';

// Components
import { CheckboxButton } from '@CommonScene/materialUIComponents/PrimaryButton';

// Utils
import { compose } from '@Utils';
import shortid from 'shortid';
import { translation } from '@Utils/translation';

// Styles
import useStyles from './styles';

const FilterContent = ({ filters, toggleStatusChecked }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      {filters.map((filter, filterIndex) =>
        filter.items.length ? (
          <Grid key={shortid.generate()} item className={classes.filterSection}>
            <ListSubheader>{filter.name}:</ListSubheader>
            <Grid container spacing={2} className={classes.sectionItemsBlock}>
              {filter &&
                filter.items.map(status => {
                  return (
                    <Grid item key={shortid.generate()}>
                      <CheckboxButton
                        onClick={() =>
                          toggleStatusChecked(status.name, filterIndex)
                        }
                        checked={status.checked}
                        disableRipple
                      >
                        {translation(status.name, 'statuses')}
                      </CheckboxButton>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          <p>{`${filter.name} filter is empty; `}</p>
        )
      )}
    </Grid>
  );
};

FilterContent.propTypes = {
  filters: PropTypes.array.isRequired,
  toggleStatusChecked: PropTypes.func.isRequired
};

export default compose(observer)(FilterContent);
