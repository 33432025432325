/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper, DataTable } from '@CommonScene';
import Filter from '@StatisticsScene/scenes/NodeActivity/NodeActivityList/components/Filter';
import SummaryBlock from '@StatisticsScene/scenes/common/SummaryBlock';

// Utils
import { compose, downloadPDF } from '@Utils';

// Configs
import { tableConfig } from '@Assets/config/tables/nodeActivity';

// Constants
import { GENERATE_SUBSET_OF_DATASET } from '@Utils/constans/permissions';

export class NodeActivityList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    BaseReportingStore: PropTypes.object.isRequired,
    NodeActivityReportStore: PropTypes.object.isRequired,
    FilterStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  getReport() {
    const {
      NodeActivityReportStore: { getReport, nodeType, setPageParams, dateRange }
    } = this.props;
    setPageParams({
      limit: 25,
      skip: 0,
      types: [nodeType],
      roles: [],
      ts_from: dateRange.from,
      ts_to: dateRange.to
    });

    getReport('UTILIZATION_LVL_1').then(() => {
      const {
        NodeActivityReportStore: { setFilters }
      } = this.props;
      setFilters(['role']);
    });
  }

  componentDidMount() {
    this.getReport();
  }

  componentWillUnmount() {
    const {
      FilterStore: { reset },
      NodeActivityReportStore: { resetDetails }
    } = this.props;
    resetDetails();
    reset();
  }

  getHeaderOptions = () => ({
    title: this.props.t('can.sidebar.nodeActivityStatistics')
  });

  applyFilter = () => {
    const {
      FilterStore: { selectedFilters },
      NodeActivityReportStore: { getReport, pageParams }
    } = this.props;
    getReport(
      'UTILIZATION_LVL_1',
      {
        ...pageParams,
        roles: selectedFilters.role
      },
      true
    );
  };

  getFilterOptions() {
    return {
      applyHandler: this.applyFilter
    };
  }

  getButtonOptions = () => {
    const {
      t,
      BaseReportingStore: { getPDFContent },
      NodeActivityReportStore: {
        pageParams,
        report: { aggregation }
      },
      FilterStore: { selectedFilters },
      AuthStore: { permissions },
      CommonStore: { pending }
    } = this.props;

    return {
      isShown: permissions.includes(GENERATE_SUBSET_OF_DATASET),
      isDisabled: pending,
      text: t('common.export'),
      onClickHandler: () =>
        getPDFContent('UTILIZATION_LVL_1', {
          ...pageParams,
          roles: selectedFilters.role,
          // Get all items without skipping
          skip: 0,
          limit: aggregation.reduce((acc, val) => val.total + acc, 0)
        }).then(() => {
          downloadPDF();
        })
    };
  };

  fetchTableData = () => {
    const {
      NodeActivityReportStore: {
        updatePageParams,
        pageParams,
        getReport,
        report,
        details
      },
      FilterStore: { selectedFilters }
    } = this.props;
    const totalItemsCount = report.aggregation.reduce(
      (acc, val) => Number(val.total) + acc,
      0
    );
    updatePageParams({
      skip: pageParams.skip + pageParams.limit,
      lastPage: totalItemsCount === details.length,
      roles: selectedFilters.role || []
    });
    if (totalItemsCount !== details.length) {
      getReport('UTILIZATION_LVL_1');
    }
  };

  render() {
    const { NodeActivityReportStore } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        filterOptions={this.getFilterOptions()}
        buttonOptions={this.getButtonOptions()}
      >
        <Filter />
        <SummaryBlock
          totalLabel="Total Activity Level"
          totalValueKey="activity"
          items={NodeActivityReportStore.report.aggregation.map(item => {
            return {
              total: item.total,
              activity: item.activity,
              status: item.status
            };
          })}
        />
        <DataTable
          tableConfig={tableConfig}
          tableData={NodeActivityReportStore.details}
          fetchTableData={this.fetchTableData}
          hasMoreData={!NodeActivityReportStore.pageParams.lastPage}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject(
    'BaseReportingStore',
    'NodeActivityReportStore',
    'AuthStore',
    'CommonStore',
    'FilterStore'
  ),
  observer
)(NodeActivityList);
