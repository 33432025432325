import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { CalendarIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';
import { formatDate } from '@Utils/formatting';

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      position: 'relative',
      color: '#A095AE',
      cursor: 'pointer',
      background: '#F2EEF6',
      display: 'inline-block',
      lineHeight: '16px',
      padding: `${theme.spacing()}px 40px ${theme.spacing()}px ${theme.spacing(
        2
      )}px`,
      border: '1px solid #E9E2F4',
      borderRadius: '17px'
    },
    icon: {
      position: 'absolute',
      top: '7px',
      right: '15px'
    }
  };
});

export const Label = ({ label, value, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} onClick={onClick}>
      {`${label}: `}
      {value || formatDate(Date.now(), 'MM/DD/YYYY')}
      <CalendarIcon className={classes.icon} />
    </div>
  );
};

Label.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default compose(observer)(Label);
